import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { RootState } from "../../redux/store";
import { FormInput } from "../../components";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { listingHotels } from "../../redux/hotels/actions";
import {
  addOutlets,
  createOutletUsers,
  editSelectedOutlet,
  editSelectedOutlets,
  getColumns,
  listingOutletsByhotels,
  listingOutletsLoading,
} from "../../redux/outlets/actions";
import FileUpload from "../forms/FileUpload";
import axios from "axios";
import _ from "lodash";
import { APICore } from "../../helpers/api/apiCore";
import Loader from "../../components/Loader";

const HotelOutLetEditForm = () => {
  const { selectedUsers, colleges } = useSelector(
    (state: RootState) => state.UsersReducer
  );
  const { hotelsList, page, limit, selectedHotels } = useSelector(
    (state: RootState) => state.HotelsReducer
  );
  const { outletsList, selectedOutlets, columnsList,editSucess,outletsloading } = useSelector(
    (state: RootState) => state.OutletsReducer
  );
  const [groomingImages, setgroomingImagesFile] = useState<any[]>([]);
  const [howToImages, sethowToImages] = useState<any[]>([]);
  const [outletImages, setoutletImages] = useState<any[]>([]);

  const user: any = localStorage.getItem("userLoggedIn");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [responsiveModal, setResponsiveModal] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [howToImageLoading, setHowToImageLoading] = useState(false);
  const [outletImagesLoading, setOutletImageLoading] = useState(false);
 
   useEffect(() => {
    setoutletImages([]);
    setgroomingImagesFile([]);
    sethowToImages([]);
    dispatch(getColumns());
   
    if (id) {
      dispatch(listingOutletsLoading())
      dispatch(editSelectedOutlets({ id: id }));
      dispatch(listingHotels({ page: 1, limit: 1000 }));
    } else {
      dispatch(listingHotels({ page: 1, limit: 1000 }));
    }
  }, []);

  const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.hotelName ? list.hotelName : " ",
      })
    );
    return hotel;
  };

  useEffect(() => {
    setoutletImages([]);
    setgroomingImagesFile([]);
    sethowToImages([]);

    if (selectedOutlets) {
      reset({
        outletName: selectedOutlets?.outletName,
        hotel: hotelOptionsListForSearch(hotelsList)?.find(
          (hotel: any) => hotel.value === selectedOutlets?.hotelId
        ),
        Abbreviation: selectedOutlets?.Abbreviation,
        billingAddress: selectedOutlets?.billingAddress,
        attendanceEmail: selectedOutlets?.attendanceEmail,
        invoiceEmail: selectedOutlets?.invoiceEmail,
        outletAdminNo: selectedOutlets?.outletAdminNo,
        startingSerialNumber: selectedOutlets?.startingSerialNumber,
        youtubeLink: selectedOutlets?.youtubeLink,
        sunday: selectedOutlets?.sunday,
        monday: selectedOutlets?.monday,
        tuesday: selectedOutlets?.tuesday,
        wednesday: selectedOutlets?.wednesday,
        thursday: selectedOutlets?.thursday,
        friday: selectedOutlets?.friday,
        saturday: selectedOutlets?.saturday,
        jobRemarks: selectedOutlets?.jobRemarks,
        phRate: selectedOutlets?.phRate,
        specialRate: selectedOutlets?.specialRate,
      });

      OutletImageReRendering(
        selectedOutlets?.groomingImages,
        "Grooming Image",
        `http://localhost:5001/api/user/images/`,
        // `https://jobs.doingbydo.com/api/user/images/${images}`,
        "setgroomingImagesFile"
      );

      OutletImageReRendering(
        selectedOutlets?.howToImages,
        "Navigation Image",
        `http://localhost:5001/api/user/images/`,
        // `https://jobs.doingbydo.com/api/user/images/${images}`,
        "sethowToImages"
      );

      OutletImageReRendering(
        selectedOutlets?.outletImages,
        "Outlet Image",
        `http://localhost:5001/api/user/images/`,
        // `https://jobs.doingbydo.com/api/user/images/${images}`,
        "setoutletImages"
      );
    }
    const arra: any[] = [];
    const resultArr = selectedOutlets?.invoiceColumns?.map(
      (col: any, index: any) => {
        arra.push({
          columns: [
            {
              id: index,
              value: col._id,
              label: col.columnName ? col.columnName : " ",
            },
          ],
        });

        return arra;
      }
    );

    resetCol({
      extraInvoiceColumns: arra,
    });
  }, [selectedOutlets]);

  const methods = useForm({
    defaultValues: {
      outletName: "",
      hotel: "",
      Abbreviation: "",
      billingAddress: "",
      attendanceEmail: "",
      invoiceEmail: "",
      outletAdminNo: "",
      startingSerialNumber: "",
      youtubeLink: "",
      sunday: "10",
      monday: "10",
      tuesday: "10",
      wednesday: "10",
      thursday: "10",
      friday: "10",
      saturday: "10",
      jobRemarks: "",
      phRate: "",
      specialRate: "",
    },
  });

  const methodsForUser = useForm({
    defaultValues: {
      fullname: "",
      username: "",
      password: "",
    },
  });
  const {
    handleSubmit: handleSubmitForUsers,
    register: register2,
    control: controlUsers,
    getValues: getUsersValues,
    reset: resetUsersValues,
    formState: { errors: errors2 },
  } = methodsForUser;

  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const methodsForCol = useForm({
    defaultValues: {
      extraInvoiceColumns: [
        {
          columns: [],
        },
      ],
    },
  });
  const {
    handleSubmit: handleSubmitForCol,
    register: registerCol,
    control: controleCol,
    getValues: getValuesCol,
    reset: resetCol,
    formState: { errors: errorsCol },
  } = methodsForCol;
  const { fields, append, remove } = useFieldArray({
    control: controleCol,
    name: "extraInvoiceColumns",
  });

  const OutletImageReRendering = async (
    data: any,
    name: any,
    url: any,
    type: any
  ) => {
    const URL_image = "https://jobs.doingbydo.com/api/user/images/";
    // const URL_image = "http://localhost:5001/api/user/images/";
    const res = await data?.map((image: any, index: any) => {
      setImageLoading(true);
      setHowToImageLoading(true);
      setOutletImageLoading(true);
      axios
        .get(`${URL_image}${image}`)
        .then(async (response) => await new Blob([response.data]))
        .then(async (blob) => {
          const f = await new File([blob], name);
          const test = await Object.assign(f, {
            preview: `${URL_image}${image}`,
          });

          if (type === "setgroomingImagesFile") {
            return setgroomingImagesFile((prevData) => [...prevData, test]);

            // setgroomingImagesFile([
            //   ...groomingImages,
            //   Object.assign(f, { preview: url }),
            // ]);
          } else if (type === "setoutletImages") {
            return setoutletImages((prevData) => [...prevData, test]);
            // setoutletImages([
            //   ...outletImages,
            //   Object.assign(f, { preview: url }),
            // ]);
          } else if (type === "sethowToImages") {
            return sethowToImages((prevData) => [...prevData, test]);
            //  index === 0 ? sethowToImages((prevData) => [test]):
            // sethowToImages([...howToImages, Object.assign(f, { preview: url })]);
          }
        });
    });

    return res;
  };

  const addOutletUsers = (details: any) => {
    
    dispatch(createOutletUsers({ outlet: id, ...details }));
    setResponsiveModal(!responsiveModal);
  };
  const api = new APICore();
  const uploadFiles = (files: any, name: any) => {
    if (name === "groomingImages") {
      setgroomingImagesFile((prevData) => files);
      // setgroomingImagesFile([
      //   ...groomingImages,
      //   Object.assign(f, { preview: url }),
      // ]);
    }
    if (name === "outletImages") {
      setoutletImages((prevData) => files);
      // setoutletImages([
      //   ...outletImages,
      //   Object.assign(f, { preview: url }),
      // ]);
    }
    if (name === "howToImages") {
      sethowToImages((prevData) => files);
      // sethowToImages([...howToImages, Object.assign(f, { preview: url })]);
    }
    // formData.append(name, files[0]);
  };
  const removeFiles = (files: any) => {
    console.log("the fiesl in ", files);
    if (files[0]?.name === "Grooming Image") {
      console.log("the images", groomingImages[0].preview.split("images/"));
       setgroomingImagesFile(files)
    } else if (files[0]?.name === "Outlet Image") {
       setoutletImages(files)
    } else if (files[0]?.name === "Navigation Image") {
       sethowToImages(files)
    }
  };
  const FilesUploadLogicGrooming = useMemo(() => {
    if (groomingImages.length > 0) {
      setImageLoading(false);
    }
    return (
      <FileUpload
        showPreview={true}
        name={"groomingImages"}
        files={groomingImages}
        uploadFiles={uploadFiles}
        removeFiles={removeFiles}
      />
    );
  }, [groomingImages]);
  const FilesUploadLogicOutletImage = useMemo(() => {
    if (outletImages?.length > 0) setOutletImageLoading(false);
    return (
      <FileUpload
        showPreview={true}
        name={"outletImages"}
        files={outletImages}
        uploadFiles={uploadFiles}
        removeFiles={removeFiles}
      />
    );
  }, [outletImages]);
  const FilesUploadLogicHowToImages = useMemo(() => {
    if (howToImages?.length > 0) setHowToImageLoading(false);
    return (
      <FileUpload
        showPreview={true}
        name={"howToImages"}
        files={howToImages}
        uploadFiles={uploadFiles}
        removeFiles={removeFiles}
      />
    );
  }, [howToImages]);



  const BasicInputElements = useMemo(() => {
    const goBack = async () => {
      await setgroomingImagesFile([]);
      await setoutletImages([]);
      await sethowToImages([]);
      navigate(-1);
    };
    const formData = new FormData();
    const saveUsersDetails = async (values: any) => {
      let columnsListArray: any = [];

      await getValuesCol()?.extraInvoiceColumns?.map(async (col: any) => {
        columnsListArray.push(col?.columns?.value);
      });
      groomingImages?.map((files: any) => {
        files?.preview?.startsWith("blob:") &&
          formData.append("groomingImages", files);
      });

      outletImages?.map((files: any) => {
        files?.preview?.startsWith("blob:") &&
          formData.append("outletImages", files);
      });
      howToImages?.map((files: any) => {
        files?.preview?.startsWith("blob:") &&
          formData.append("howToImages", files);
      });
      const groomingTempArr = groomingImages?.filter((files:any) =>  !files?.preview?.startsWith("blob:"))
    
      const howToImageTempArr = howToImages?.filter((files:any) =>  !files?.preview?.startsWith("blob:"))
      
      const howoutletImageTempArr = outletImages?.filter((files:any) =>  !files?.preview?.startsWith("blob:"))
     
     
     
      formData.append(
        "data",
        JSON.stringify({
          groomingImages: selectedOutlets?.groomingImages?.filter((existingImaes:any) => groomingTempArr.find((editedImages) => existingImaes === editedImages.preview?.split('/images/')[1])),
          howToImages: selectedOutlets?.howToImages?.filter((existingImaes:any) => howToImageTempArr.find((editedImages) =>existingImaes === editedImages.preview?.split('/images/')[1])),
          outletImages: selectedOutlets?.outletImages?.filter((existingImaes:any) => howoutletImageTempArr.find((editedImages) => existingImaes === editedImages.preview?.split('/images/')[1])),
          extraInvoiceColumns: columnsListArray,
          outletName: values.outletName,
          hotel: values.hotel?.value,
          Abbreviation: values.Abbreviation,
          billingAddress: values.billingAddress,
          attendanceEmail: values.attendanceEmail,
          invoiceEmail: values.invoiceEmail,
          outletAdminNo: values.outletAdminNo,
          startingSerialNumber: values.startingSerialNumber,
          youtubeLink: values.youtubeLink,
          sunday: values.sunday,
          monday: values.monday,
          tuesday: values.tuesday,
          wednesday: values.wednesday,
          thursday: values.thursday,
          friday: values.friday,
          saturday: values.saturday,
          jobRemarks: values.jobRemarks,
          phRate: values.phRate,
          specialRate: values.specialRate,
        })
      );

      if (id) {
        formData.append("outletId", id);
        await dispatch(listingOutletsLoading())
        await dispatch(editSelectedOutlet(formData));
      } else {
        await  dispatch(listingOutletsLoading())
        await dispatch(addOutlets(formData));
      }

      goBack();
    };
    const hotelOptionsListForSearch = (data: any) => {
      let hotel: any = [];
      data?.data?.map((list: any, index: any) =>
        hotel.push({
          id: index,
          value: list._id,
          label: list.hotelName ? list.hotelName : " ",
        })
      );
      return hotel;
    };

    const columsnListForSearch = (data: any) => {
      let columns: any = [{ value: "new", label: "Add New..." }];

      data?.map((list: any, index: any) =>
        columns.push({
          id: index,
          value: list._id,
          label: list.columnName ? list.columnName : " ",
        })
      );

      return columns;
    };


    return (
      <>
        <Card>
          <Card.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "x-large",
                cursor: "pointer",
              }}
              className="mb-3"
            >
              <h4>Outlet Information</h4>
              <div
                onClick={async () => {
                  await setgroomingImagesFile([]);
                  await sethowToImages([]);
                  await setoutletImages([]);
                  goBack();
                }}
              >
                <i className="bi bi-x"></i>
              </div>
            </div>
            <form onSubmit={handleSubmit(() => saveUsersDetails(getValues()))}>
              <Row>
                <Col md={3}>
                  <Controller
                    control={control}
                    name="hotel"
                    rules={{
                      required: "Please, select at least one hotel",
                    }}
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Hotel Name
                        </label>
                        <Select
                          {...field}
                          isMulti={false}
                          options={hotelOptionsListForSearch(hotelsList)}
                          value={field.value}
                          placeholder="Hotel"
                        />
                        {/* <Typeahead
                          {...field}
                          id="basic-typeahead-single"
                          multiple={false}
                          register={register}
                          // onInputChange={(e) => searchByName('name', e)}
                          options={hotelOptionsListForSearch(hotelsList)}
                          placeholder="Hotel Name"
                        /> */}
                      </div>
                    )}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Outlet Name"
                    type="text"
                    name="outletName"
                    placeholder="Outlet Name"
                    containerClass={"mb-3"}
                    register={register}
                    key="outletName"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    name="Abbreviation"
                    label="Abbreviation"
                    type="text"
                    placeholder="Abbreviation"
                    containerClass="mb-3"
                    register={register}
                    key="Abbreviation"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Billing Address"
                    type="text"
                    name="billingAddress"
                    placeholder="Billing Address"
                    containerClass={"mb-3"}
                    register={register}
                    key="billingAddress"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormInput
                    label="Attendance Email"
                    type="text"
                    name="attendanceEmail"
                    placeholder="Attendance email"
                    containerClass={"mb-3"}
                    register={register}
                    key="attendanceEmail"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Invoice Email"
                    type="text"
                    name="invoiceEmail"
                    placeholder="Inovice Email"
                    containerClass={"mb-3"}
                    register={register}
                    key="invoiceEmail"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Outlet Admin Number"
                    type="text"
                    name="outletAdminNo"
                    placeholder="Admin Number"
                    containerClass={"mb-3"}
                    register={register}
                    key="outletAdminNo"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Starting Serial Number"
                    type="text"
                    name="startingSerialNumber"
                    placeholder="Starting Serial Number"
                    containerClass={"mb-3"}
                    register={register}
                    key="startingSerialNumber"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormInput
                    label="Youtube Link"
                    type="text"
                    name="youtubeLink"
                    placeholder="Youtube Link"
                    containerClass={"mb-3"}
                    register={register}
                    key="youtubeLink"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="PH (Public Hotels) Rate"
                    type="text"
                    name="phRate"
                    placeholder="PH (Public Hotels) Rate"
                    containerClass={"mb-3"}
                    register={register}
                    key="phRate"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="OC (Holiday) Rate"
                    type="text"
                    name="specialRate"
                    placeholder="OC (Holiday) Rate"
                    containerClass={"mb-3"}
                    register={register}
                    key="specialRate"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={9}>
                  <FormInput
                    label="Job remarks"
                    type="textarea"
                    name="jobRemarks"
                    placeholder="Job remarks"
                    containerClass={"mb-3"}
                    register={register}
                    key="jobRemarks"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Navigation Images</label>
                  {howToImageLoading ?  <div style={{ display: "flex", justifyContent: "center" }}>
                      Loading....
                    </div> : FilesUploadLogicHowToImages}
                  {/* <FileUpload
                    name={"howToImages"}
                    files={howToImages}
                    uploadFiles={uploadFiles}
                  /> */}
                </Col>
                <Col md={4}>
                  <label>Groomings Images</label>
                  {imageLoading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      Loading....
                    </div>
                  ) : (
                    FilesUploadLogicGrooming
                  )}
                  {/* <FileUpload
                    showPreview={true}
                    name={"groomingImages"}
                    files={groomingImages}
                    uploadFiles={uploadFiles}
                  /> */}
                </Col>
                <Col md={4}>
                  <label>Outlet Images</label>
                  {outletImagesLoading ?  <div style={{ display: "flex", justifyContent: "center" }}>
                      Loading....
                    </div> :   FilesUploadLogicOutletImage}
                  {/* {outletImages && <FileUpload
                    name={"outletImages"}
                    files={outletImages}
                    uploadFiles={uploadFiles}
                  />} */}
                </Col>
              </Row>
              <h1>Hourly Rates ($)</h1>
              <Row>
                <Col md={1}>
                  <FormInput
                    label="Monday"
                    type="text"
                    name="monday"
                    placeholder="Monday"
                    containerClass={"mb-3"}
                    register={register}
                    key="monday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Tuesday"
                    type="text"
                    name="tuesday"
                    placeholder="Tuesday"
                    containerClass={"mb-3"}
                    register={register}
                    key="tuesday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Wednesday"
                    type="text"
                    name="wednesday"
                    placeholder="Wednesday"
                    containerClass={"mb-3"}
                    register={register}
                    key="wednesday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Thursday"
                    type="text"
                    name="thursday"
                    placeholder="Thursday"
                    containerClass={"mb-3"}
                    register={register}
                    key="thursday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Friday"
                    type="text"
                    name="friday"
                    placeholder="Friday"
                    containerClass={"mb-3"}
                    register={register}
                    key="friday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Saturday"
                    type="text"
                    name="saturday"
                    placeholder="Saturday"
                    containerClass={"mb-3"}
                    register={register}
                    key="saturday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Sunday"
                    type="text"
                    name="sunday"
                    placeholder="Sunday"
                    containerClass={"mb-3"}
                    register={register}
                    key="sunday"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>

              {fields.map((field, index) => (
                <div key={field.id} className="mb-3">
                  <Controller
                    control={controleCol}
                    name={`extraInvoiceColumns.${index}.columns`}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        options={columsnListForSearch(columnsList)}
                        onChange={(selectedOption: any) => {
                          if (selectedOption?.value === "new") {
                            const newLabel = window.prompt("Enter new option:");
                            if (newLabel) {
                              const baseUrl = `outlet/extraColumns`;
                              api
                                .create(`${baseUrl}?columnName=${newLabel}`, {})
                                .then((response: any) => {
                                  onChange({
                                    value: response?.data?.result?._id,
                                    label: newLabel,
                                  });
                                });
                            }
                          } else {
                            onChange(selectedOption);
                          }
                        }}
                        onBlur={onBlur}
                        value={value}
                        isClearable
                        placeholder="Select an option"
                      />
                    )}
                  />
                  <button
                    type="button"
                    className="btn btn-danger mt-2"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => append({ columns: [] })}
              >
                Add More Attendance Columns
              </button>
              {/* <button type="button"
        className="btn btn-primary" style={{marginLeft:'2%'}}>
        Create New Attendance Column Option
      </button> */}

              <Row>
                <Col style={{ display: "flex", justifyContent: "end" }}>
                 <Button type="submit">
                  { outletsloading ?  (<span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>) : (<div>Save Outlet Details</div>)}
                  
                      {/* {outletsloading ? :<span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>? {<div>Save Outlet Details</div>}} */}
                      </Button>
                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
      </>
    );
  }, [
    outletImages,
    howToImages,
    groomingImages,
    handleSubmit,
    control,
    register,
    errors,
    fields,
    navigate,
    getValuesCol,
    selectedOutlets?.groomingImages,
    selectedOutlets?.howToImages,
    selectedOutlets?.outletImages,
    id,
    dispatch,
    getValues,
    hotelsList,
    controleCol,
    columnsList,
    api,
    remove,
    append,
  ]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {id ? <h1>Edit Outlet</h1> : <h1>Add Outlet</h1>}
        <div>
          {id && (
            <Button
              style={{ float: "right" }}
              variant="success"
              className="waves-effect waves-light"
              onClick={() => setResponsiveModal(true)}
            >
              <i className="mdi mdi-plus-circle me-1"></i> Add New User
            </Button>
          )}
        </div>
      </div>
      <>
        <Card>
          <Card.Body>
            {/* {loader? <Loader/> : }  */}
            {(id && outletsloading) ? <Loader/> : BasicInputElements}
            {/* <BasicInputElements   /> */}
          </Card.Body>
        </Card>
        <Modal show={responsiveModal} onHide={() => setResponsiveModal(false)}>
          <Modal.Body className="p-2">
            {
              <>
                <form onSubmit={handleSubmitForUsers(addOutletUsers)}>
                  <FormInput
                    label="Full Name"
                    type="text"
                    name="fullname"
                    placeholder="Enter name"
                    containerClass={"mb-3"}
                    register={register2}
                  />
                  <FormInput
                    label="User Name"
                    type="text"
                    name="username"
                    placeholder="Enter user name"
                    containerClass={"mb-3"}
                    register={register2}
                  />
                  <FormInput
                    label="Password"
                    type="password"
                    name="password"
                    placeholder="Enter name"
                    containerClass={"mb-3"}
                    register={register2}
                  />
                  <div className="text-end">
                    <Button
                      variant="success"
                      type="submit"
                      className="waves-effect waves-light me-1"
                    >
                      Save
                    </Button>
                    <Button
                      variant="danger"
                      className="waves-effect waves-light"
                      onClick={() => {
                        // reset({
                        //   name: "",
                        //   email: "",
                        //   roles: "",
                        //   contactNo: "",
                        //   password: "",
                        //   isAvailable:""
                        // })
                        setResponsiveModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </form>
              </>
            }
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default HotelOutLetEditForm;
