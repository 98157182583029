import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  listStaffStatus as listStaffStatusApi,
  listStaffStatusByOutlet as listStaffStatusByOutletApi
} from "../../helpers/api/staffStatus";
import { StaffStatusActionTypes } from "./constants";
import { apiOutletsResponseError, apiOutletsResponseSucces } from "./actions";

function* listStaffStatus({
  type,
  payload
}: any):SagaIterator{
  try{
    console.log('the payload', payload)
    const repsonse = yield call(listStaffStatusApi, payload);
    yield put(apiOutletsResponseSucces(StaffStatusActionTypes.LIST_STAFF_STATUS_LOADING,{}))
    yield put(apiOutletsResponseSucces(StaffStatusActionTypes.LIST_STAFF_STATUS,repsonse.data.result))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(StaffStatusActionTypes.LIST_STAFF_STATUS, err));
 
  }
}

function* listStaffStatusLoading({
 
}: any):SagaIterator{
  try{
    yield put(apiOutletsResponseSucces(StaffStatusActionTypes.LIST_STAFF_STATUS_LOADING,{}))
    // yield put(apiOutletsResponseSucces(StaffStatusActionTypes.LIST_STAFF_STATUS,repsonse.data.result))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(StaffStatusActionTypes.LIST_STAFF_STATUS_LOADING, err));
 
  }
}
function* getStaffStatusById({
  type,
  payload
}: any):SagaIterator{
  try{
    console.log('the payload', payload)
    const repsonse = yield call(listStaffStatusByOutletApi, payload);
    yield put(apiOutletsResponseSucces(StaffStatusActionTypes.LIST_STAFF_STATUS_LOADING,{}))
    yield put(apiOutletsResponseSucces(StaffStatusActionTypes.LIST_STAFF_STATUS_BY_OUTLET,repsonse.data.result))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(StaffStatusActionTypes.LIST_STAFF_STATUS_BY_OUTLET, err));
 
  }
}


function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    yield put(apiOutletsResponseSucces(StaffStatusActionTypes.RESET_STAFF_STATUS,{}))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(StaffStatusActionTypes.RESET_STAFF_STATUS, err));
 
  }
}


export function* watchListingStaffStatus() {
  yield takeEvery(StaffStatusActionTypes.LIST_STAFF_STATUS, listStaffStatus);
}

export function* watchgetStaffStatusById() {
  yield takeEvery(StaffStatusActionTypes.LIST_STAFF_STATUS_BY_OUTLET, getStaffStatusById);
}

export function* watchresetValues() {
  yield takeEvery(StaffStatusActionTypes.RESET_STAFF_STATUS, resetValues);
}

export function* watchListingStaffStatusLoading() {
  yield takeEvery(StaffStatusActionTypes.LIST_STAFF_STATUS_LOADING, listStaffStatusLoading);
}

function* StaffStatusSaga() {
  yield all([
    fork(watchListingStaffStatus),
    fork(watchgetStaffStatusById),
    fork(watchresetValues),
    fork(watchListingStaffStatusLoading)
  ]);
}

export default StaffStatusSaga;
