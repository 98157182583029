import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { FormInput } from "../../../../components";

interface ContactDetailsProps {
  contacts: {
    id: number;
    name: string;
    avatar: string;
    position: string;
    website: string;
    posts: string;
    followers: string;
    followings?: string;
    release?: any;
    vacancy?: any;
    priority?: any;
    endDate?:any;
    startTime?:any;
    endTime?:any;
    hourlyPay?:any;
    totalPay?:any;
  }[];
  vacanySetValue?: any;
  releaseSetValue?: any
}

const ContactDetails = ({ contacts, vacanySetValue , releaseSetValue}: ContactDetailsProps) => {
  console.log("the contact", contacts)
  const [isEditRelease, setIsEditRelease] = useState(false);
  const [isEditVacany, setIsEditVacanyc] = useState(false)
  const [releaseValue, setReleaseValue] = useState(
    contacts[0]?.release ? contacts[0]?.release : 0
  );
  const [vacanyValue, setVacanyValue] = useState(
    contacts[0]?.vacancy ? contacts[0]?.vacancy : 0
  );
  const handleClick = () => {
    setIsEditRelease(true);
  };
  const handleClickVacany = () =>{
    setIsEditVacanyc(true)
  }
  useEffect(() => {
    setReleaseValue(contacts[0]?.release);
    setVacanyValue(contacts[0]?.vacancy)
  }, [contacts]);
  return (
    <>
      <Row>
        {(contacts || []).map((user, index) => {
          return (
            <Col key={index}>
              <Card className="text-center">
                <Card.Body>
                  <div >
                 <div  style={{display:'flex', fontWeight:800, justifyContent:'space-between'}}>
                 <div style={{fontWeight:700, fontSize:'20px'}}>Name of the Slots:</div>
                    <span >
                    {user.name}
                    </span>
                 </div>
                 <div style={{display:'flex',justifyContent:'space-between'}}>
                 <div style={{fontWeight:700, fontSize:'20px'}}>Hotel & Outlet:</div>
                    <p className="text-muted">
                      @{user.position} <span> | </span>{" "}
                      <span>
                        <Link to="#" className="text-pink">
                          {user.website}
                        </Link>
                      </span>
                    </p>
                 </div>
                

                    <Row >
                      <div style={{display:"flex", justifyContent:'space-between'}}>
                      
                          <div  style={{fontWeight:700, fontSize:'20px'}}>
                            Slot Starts In:
                          </div>
                          <h4>{user.posts}</h4>
                      </div>
                    </Row>
                    {/* <Row >
                      <div style={{display:"flex", justifyContent:'space-between'}}>
                      
                          <div  style={{fontWeight:700, fontSize:'20px'}}>
                            Slot Ends In:
                          </div>
                          <h4>{user.endDate}</h4>
                      </div>
                    </Row> */}
                    <Row >
                      
                      <div style={{display:"flex", justifyContent:'space-between'}}>
                      
                      <div  style={{fontWeight:700, fontSize:'20px'}}>
                        Slot Start Time:
                      </div>
                      <h4>{user.startTime}</h4>

                  </div>
               
                      
                    </Row>
                    <Row>
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                  
                  <div  style={{fontWeight:700, fontSize:'20px'}}>
                    Slot End Time:
                  </div>
                  <h4>{user.endTime}</h4>
                  
              </div>
                    </Row>
                    <Row>
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                  
                  <div  style={{fontWeight:700, fontSize:'20px'}}>
                    Slot Hourly Pay:
                  </div>
                  <h4>{user.hourlyPay}</h4>
                  
              </div>
                    </Row>
                    <Row>
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                  
                  <div  style={{fontWeight:700, fontSize:'20px'}}>
                    Slot TotalPay:
                  </div>
                  <h4>{user.totalPay}</h4>
                  
              </div>
                    </Row>
                    <Row className="mt-4">
                      <Col xs={4}>
                        <div className="mt-3">
                          <h4>{user.followers}</h4>
                          <p className="mb-0 text-muted text-truncate">
                            Position
                          </p>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="mt-3">
                          {isEditRelease ? (
                            <FormInput
                              name="release"
                              type="text"
                              onBlur={(e) => {
                                // setIsEditRelease(!isEditRelease)
                                setReleaseValue(e.target.value);
                              }}
                              onChange={(e) => {
                                releaseSetValue(e.target.value)
                                setReleaseValue(e.target.value);
                              }}
                              defaultValue={user.release}
                            />
                          ) : (
                            <h4 onClick={handleClick}>{releaseValue}</h4>
                          )}
                          <p className="mb-0 text-muted text-truncate">
                            Released
                          </p>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="mt-3">
                        {isEditVacany ? (
                            <FormInput
                              name="vacany"
                              type="text"
                              onBlur={(e) => {
                                // setIsEditRelease(!isEditRelease)
                                setVacanyValue(e.target.value);
                              }}
                              onChange={(e) => {
                                vacanySetValue(e.target.value)
                                setVacanyValue(e.target.value);
                              }}
                              defaultValue={user.vacancy}
                            />
                          ) : (
                            <h4 onClick={handleClickVacany}>{vacanyValue}</h4>
                          )}
                          {/* <h4>{user.vacancy}</h4> */}
                          <p className="mb-0 text-muted text-truncate">
                            Vacancy
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ContactDetails;
