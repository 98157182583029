export enum PushNotificationActionTypes {
    API_NOTIFICATIONS_RESPONSE_SUCCESS = "@@notifications/API_NOTIFICATIONS_RESPONSE_SUCCESS",
    API_NOTIFICATIONS_RESPONSE_ERROR = "@@invoices/API_NOTIFICATIONS_RESPONSE_ERROR",
  
    // loading
   
    CREATE_NEW_NOTIFICATIONS_LOADING = "@@notifications/CREATE_NEW_NOTIFICATIONS_LOADING",
    
  // ACTIONS
   
    CREATE_NEW_NOTIFICATIONS = "@@notifications/CREATE_NEW_NOTIFICATIONS",
   
    
    RESET_NOTIFICATIONS = "@@notifications/RESET_NOTIFICATIONS",
  }