// import { Form } from "react-bootstrap";

import moment from "moment";


export const searchFilterForGroups = 
[
    {
        key:"page"
    },
    {
        key: "limit"
    },
    {
        key: "name"
    },
    {
        key: "userStatus"
    }
]

// table columns
export const UsersTableColumns =  (data: any) => {
const arr:any= [];
 data?.data?.map((details:any) => {
   return  arr.push({
        id: details._id,
        name:details?.name,
        contactNumber: details?.contactNumber,
        IsForeigner: details?.IsForeigner,
        registeredDate: moment(details?.createdAt).format('YYYY/MM/DD HH:mm:ss'),
        key: details?._id
      
    }) 
}) 

return arr;
}

export const PaymentHistoryColumns =  (data: any) => {
    const arr:any= [];
    console.log("the dat", data)
     data?.data?.map((details:any, index:any) => {
       return  arr.push({
            index: index,
            id: details._id,
            hotelName:details?.hotelName,
            outletName: details?.outletName,
            amount: details?.amount,
            remarks: details?.remarks
          
        }) 
    }) 
    
    return arr;
    }

    export const RedeemHistoryColumns =  (data: any) => {
        const arr:any= [];
        console.log("the dat", data)
         data?.data?.map((details:any) => {
           return  arr.push({
                id: details._id,
                amount: details?.amount,
                updatedAt: details?.updatedAt
              
            }) 
        }) 
        
        return arr;
        }
  

