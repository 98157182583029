import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore

// helpers
import {
  deleteGroup as deleteGroupApi,
  listgroups as listgroupsApi,
  listGroupsById as listGroupsByIdApi,
  addgroups as addgroupsApi,
  editGroups as editGroupsApi
  // logout as logoutApi,
  // signup as signupApi,
  // forgotPassword as forgotPasswordApi,
} from "../../helpers/api/groups";


// constants
import { GroupActionTypes } from "./constants";
// actions
import { apiGroupsResponseError, apiGroupsResponseSuccess } from "./actions";
import { toast } from "react-toastify";


function* listGroupsLoading(): SagaIterator{
  try{
    yield put(apiGroupsResponseSuccess(GroupActionTypes.LIST_GROUPS_LOADING, {}))
  }
  catch(err: any){
    yield put(apiGroupsResponseError(GroupActionTypes.LIST_GROUPS_LOADING,err))
  }
}
function* createGroupsLoading(): SagaIterator{
  try{
    yield put(apiGroupsResponseSuccess(GroupActionTypes.CREATE_GROUPS_LOADING, {}))
  }
  catch(err: any){
    yield put(apiGroupsResponseError(GroupActionTypes.CREATE_GROUPS_LOADING, err))
  }
}
function* deleteGroupsLoading(): SagaIterator{
  try{
    yield put(apiGroupsResponseSuccess(GroupActionTypes.DELETE_GROUP_LOADING, {}))
  }
  catch(err: any){
    yield put(apiGroupsResponseError(GroupActionTypes.DELETE_GROUP_LOADING, err))
  }
}
function* editGroupsLoading(): SagaIterator{
  try{
    yield put(apiGroupsResponseSuccess(GroupActionTypes.EDIT_SELECTED_GROUP_LOADING, {}))
  }
  catch(err: any){
    yield put(apiGroupsResponseError(GroupActionTypes.EDIT_SELECTED_GROUP_LOADING, err))
  }
}
function* listGroups({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(listgroupsApi, searchParams);
 
    yield put(apiGroupsResponseSuccess(GroupActionTypes.LIST_GROUPS, response.data.result))
  }
  catch(err: any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    // toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiGroupsResponseError(GroupActionTypes.LIST_GROUPS, err))
  }
}

function* createGroups({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(addgroupsApi, searchParams);
    // if (response)  yield call(listgroupsApi, searchParams);
    toast.success(response.data.info.msg);
    yield put(apiGroupsResponseSuccess(GroupActionTypes.CREATE_GROUPS, response.data.result.data))
  }
  catch(err: any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiGroupsResponseError(GroupActionTypes.CREATE_GROUPS, err))
  }
}

function* listGroupsById({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    const repsonse = yield call(listGroupsByIdApi, details);
    console.log('theresp, response', repsonse)
    yield put(apiGroupsResponseSuccess(GroupActionTypes.EDIT_GROUPSS_SELECTED, repsonse.data.result[0]))
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiGroupsResponseError(GroupActionTypes.EDIT_GROUPSS_SELECTED, err));
 
  }
}
function* deleteGroup({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log("the payel", id)
    const repsonse = yield call(deleteGroupApi, id);
    toast.success(repsonse.data.info.msg);
    // if(repsonse) yield call(listGroups,{page:0,limit:5})
    yield put(apiGroupsResponseSuccess(GroupActionTypes.DELET_GROUPS,{}))
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiGroupsResponseError(GroupActionTypes.DELET_GROUPS, err));
 
  }
}

function* editGroup({
  payload
}: any): SagaIterator{
  try{
    console.log("the payel", payload)
    debugger;
    const repsonse = yield call(editGroupsApi, payload);
    toast.success(repsonse.data.info.msg);
    // if(repsonse) yield call(listGroups,{page:0,limit:5})
    yield put(apiGroupsResponseSuccess(GroupActionTypes.EDIT_GROUPS,{}))
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiGroupsResponseError(GroupActionTypes.EDIT_GROUPS, err));
 
  }
}
function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    
    yield put(apiGroupsResponseSuccess(GroupActionTypes.RESET_GROUPS,{}))
  }
  catch(err:any){
    yield put(apiGroupsResponseError(GroupActionTypes.RESET_GROUPS, err));
 
  }
}



export function* watchListingGroupsLoading() {
  yield takeEvery(GroupActionTypes.LIST_GROUPS_LOADING, listGroupsLoading);
}
export function* watchCreatingGroupsLoading() {
  yield takeEvery(GroupActionTypes.CREATE_GROUPS_LOADING, createGroupsLoading);
}
export function* watchDeleteGroupsLoading() {
  yield takeEvery(GroupActionTypes.DELETE_GROUP_LOADING, deleteGroupsLoading);
}
export function* watchEditGroupsLoading() {
  yield takeEvery(GroupActionTypes.EDIT_SELECTED_GROUP_LOADING, editGroupsLoading);
}
export function* watchListingGroups() {
  yield takeEvery(GroupActionTypes.LIST_GROUPS, listGroups);
}
export function* watchListSupportById(): any {
  yield takeEvery(GroupActionTypes.EDIT_GROUPSS_SELECTED, listGroupsById);
}
export function* watchDeleteGroup(): any {
  yield takeEvery(GroupActionTypes.DELET_GROUPS, deleteGroup);
}
export function* watchEditGroup(): any {
  yield takeEvery(GroupActionTypes.EDIT_GROUPS, editGroup);
}

export function* watchResetSelectedGroup(): any {
  yield takeEvery(GroupActionTypes.RESET_GROUPS, resetValues);
}

export function* watchCreateGroup(): any {
  yield takeEvery(GroupActionTypes.CREATE_GROUPS, createGroups);
}

// export function* watchLogout() {
//   yield takeEvery(GroupActionTypes.LOGOUT_USER, logout);
// }

// export function* watchSignup(): any {
//   yield takeEvery(GroupActionTypes.SIGNUP_USER, signup);
// }

// export function* watchForgotPassword(): any {
//   yield takeEvery(GroupActionTypes.FORGOT_PASSWORD, forgotPassword);
// }

function* groupSaga() {
  yield all([
    fork(watchListingGroups),
    fork(watchListSupportById),
    fork(watchDeleteGroup),
    fork(watchResetSelectedGroup),
    fork(watchCreateGroup),
    fork(watchListingGroupsLoading),
    fork(watchCreatingGroupsLoading),
    fork(watchDeleteGroupsLoading),
    fork(watchEditGroupsLoading),
    fork(watchEditGroup)
  ]);
}

export default groupSaga;
