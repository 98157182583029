import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore

// helpers
import {
    CreateNotification as CreateNotificationApi
} from "../../helpers/api/notification";


// constants
import { PushNotificationActionTypes } from "./constants";
// actions
import { apiPushNotificationResponseSuccess, apiPushNotificationResponseError } from "./actions";
import { toast } from "react-toastify";

function* createPushNotification({payload: searchParams,
    type}: any): SagaIterator{
    try{
        const response = yield call(CreateNotificationApi, searchParams);
      yield put(apiPushNotificationResponseSuccess(PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS, response.data.result))
      toast.success("Send Notification Successfully")
    }
    catch(err: any){
      yield put(apiPushNotificationResponseError(PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS, err))
      toast.error("Something went wrong")
    }
  }
  function* pushNotificationLoading(): SagaIterator{
    try{
   
      yield put(apiPushNotificationResponseSuccess(PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS_LOADING, {}))
    }
    catch(err: any){
      yield put(apiPushNotificationResponseError(PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS_LOADING, err))
    }
  }

  export function* watchListingGroupsLoading() {
    yield takeEvery(PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS, createPushNotification);
  }
  export function* watchCreatingGroupsLoading() {
    yield takeEvery(PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS_LOADING, pushNotificationLoading);
  }

  function* notificationSaga() {
    yield all([
      fork(watchListingGroupsLoading),
      fork(watchCreatingGroupsLoading),
    
    ]);
  }
  
  export default notificationSaga;