// constants
import { PushNotificationActionTypes } from "./constants";

const INIT_STATE = {
 pushNotificationLoading: false
};

const NotificationReducers = (state = INIT_STATE, action: any): any => {
    switch (action.type) {
      case PushNotificationActionTypes.API_NOTIFICATIONS_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
          case PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS: {
            return {
              ...state,
              pushNotificationLoading: false,
            };
          }
          case PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS_LOADING: {
            return {
              ...state,
              pushNotificationLoading: true,
            };
          }
          default:
            return { ...state };
        }
  
      case PushNotificationActionTypes.API_NOTIFICATIONS_RESPONSE_ERROR:
        switch (action.payload.actionType) {
          case PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS: {
            return {
              ...state,
              error: action.payload.error,
              pushNotificationLoading: false,
            };
          }
          case PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS_LOADING: {
            return {
              ...state,
              error: action.payload.error,
              pushNotificationLoading: false,
            };
          }
      default:
        return { ...state };
    }
    default:
      return { ...state };
}
  };
  
  export default NotificationReducers;