import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  listStaffAttendances as listStaffAttendancesApi,
  updateStaffAttendanceById as updateStaffAttendanceByIdApi,
  completeStaffAttendanceById as completeStaffAttendanceByIdApi
} from "../../helpers/api/staffAttendance";


// constants
import { StaffAttendanceActionTypes } from "./constants";
// actions
import { apiOutletsResponseError, apiOutletsResponseSucces } from "./actions";


function* listStaffAttendance({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(listStaffAttendancesApi, searchParams);
    console.log('the users in te slosts', response)
    yield put(apiOutletsResponseSucces(StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES_LOADING, {}))
    yield put(apiOutletsResponseSucces(StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES, response.data.result))
  }
  catch(err: any){
    yield put(apiOutletsResponseError(StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES, err))
  }
}


function* listStaffAttendanceLoading(): SagaIterator{
  try{
    yield put(apiOutletsResponseSucces(StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES_LOADING, {}))
    // yield put(apiOutletsResponseSucces(StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES, response.data.result))
  }
  catch(err: any){
    yield put(apiOutletsResponseError(StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES_LOADING, err))
  }
}




function* editStaffAttendance({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    const repsonse = yield call(updateStaffAttendanceByIdApi, details);
    yield put(apiOutletsResponseSucces(StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED, repsonse.data.result[0]))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED, err));
 
  }
}


function* completeStaffAttendance({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', id)
    const repsonse = yield call(completeStaffAttendanceByIdApi, id);
    console.log('theresp, response', repsonse)
    yield put(apiOutletsResponseSucces(StaffAttendanceActionTypes.COMPLETE_ATTENDANCE_LOADING,{}))
    yield put(apiOutletsResponseSucces(StaffAttendanceActionTypes.COMPLETE_ATTENDANCE,repsonse.data.result))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(StaffAttendanceActionTypes.COMPLETE_ATTENDANCE, err));
 
  }
}





function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    yield put(apiOutletsResponseSucces(StaffAttendanceActionTypes.RESET_STAFF_ATTENDANCES,{}))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(StaffAttendanceActionTypes.RESET_STAFF_ATTENDANCES, err));
 
  }
}


export function* watchListingAttendance() {
  yield takeEvery(StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES, listStaffAttendance);
}


export function* watchListingAttendanceLoading() {
  yield takeEvery(StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES_LOADING, listStaffAttendanceLoading);
}

export function* watchEditAttendance() {
  yield takeEvery(StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED, editStaffAttendance);
}


export function* watchcompleteAttendance() {
  yield takeEvery(StaffAttendanceActionTypes.COMPLETE_ATTENDANCE, completeStaffAttendance);
}

export function* watchresetValues() {
  yield takeEvery(StaffAttendanceActionTypes.RESET_STAFF_ATTENDANCES, resetValues);
}

function* StaffAttendanceSaga() {
  yield all([
    fork(watchListingAttendance),
    fork(watchEditAttendance),
    fork(watchcompleteAttendance),
    fork(watchresetValues),
    fork(watchListingAttendanceLoading)
  ]);
}

export default StaffAttendanceSaga;
