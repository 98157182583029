import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewInvoice,
  createNewInvoiceLoading,
  DownloadInvoice,
  downloadInvoicesLoading,
  listingInvoices,
  listingInvoicesLoading,
  mergeInvoice,
  mergeInvoiceLoadingAction,
  SendInvoice,
  sendInvoicesLoading,
} from "../../redux/invoice/actions";
import { RootState } from "../../redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Col, Modal, Nav, Tab, Form, Button, Row } from "react-bootstrap";
import Table from "../../components/Table";
import Pagination from "../../components/customPaginationComponent/customPagination";
import { InvoiceListTableColumns } from "./data";
import moment from "moment";
import CommonSearchBar from "../../layouts/SearchBar";
import { listingHotels } from "../../redux/hotels/actions";
import { listingOutlets } from "../../redux/outlets/actions";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import { DateRangePicker } from "react-date-range";
import { AnyAction } from "redux";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import CreateInvoiceModal from "./createInvoiceInvoiceModal";
import CountDashboard from "../../components/CountDashboard";
import invoiceLogo from '../../assets/images/cards/clipboard.png';

const InvoiceListingPage = () => {
  // initial declare
  const { limit, page, hotel, outlet, weeknumber, invoiceName, status } =
    useParams();
  let { search } = useLocation();
  let selectedInvoicesForMerging: any = [];
  const [selectedCellId, setSelectedCellID] = useState()
  const query = new URLSearchParams(search);
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    invoicelist,
    invoicelistLoading,
    mergeInvoiceLoading,
    sendInvoiceLoading,
    downloadInvoiceLoading,
    createInvoiceLaoding,
  } = useSelector((state: RootState) => state.InvoiceReducer);
  const { hotelsList } = useSelector((state: RootState) => state.HotelsReducer);
  const { outletsList } = useSelector(
    (state: RootState) => state.OutletsReducer
  );
  const [currentPage, setCurrentPage] = useState(page);
  let currentDate = new Date();

  const [state, setState] = useState<any>([
    {
      startDate: new Date(currentDate).setMonth(currentDate.getMonth() - 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [responsiveModalDate, setResponsiveModalDate] = useState(false);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedtomerge, setSelectedtomerge] = useState<any>([]);

  // intial loading
  useEffect(() => {
    dispatch(listingHotels({ page: page, limit: 1000 }));
    dispatch(listingInvoicesLoading({}));
  }, []);

  useEffect(() => {
    navigate(
      `/invoice/list/${page}/${limit}/all/all/all/all/all?startDate=${moment(
        state[0].startDate
      ).format("YYYY-MM-DDThh:mm:ss")}&endDate=${moment(
        state[0].endDate
      ).format("YYYY-MM-DDThh:mm:ss")}`
    );
    dispatch(
      listingInvoices({
        limit: limit,
        page: 1,
        hotel: hotel === "all" ? "" : hotel,
        outlet: outlet === "all" ? "" : outlet,
        weekNumber: weeknumber === "all" ? "" : weeknumber,
        status: status === "all" ? "" : status,
        startDate: moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss"),
        endDate: moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss"),
      })
    );
  }, [state, mergeInvoiceLoading, createInvoiceLaoding]);

  const searchByHotelNameForModal = (
    name: any,
    hotelsValue: any,
    hotelselected: any
  ) => {
    if (name === "hotels") {
      dispatch(
        listingHotels({ hotelName: hotelsValue, limit: 10000, page: 0 })
      );
    }
    if (name === "outlets") {
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotelName: hotelselected,
          limit: 10000,
          page: 0,
        })
      );
    }
  };

  const searchByHotelName = (name: any, hotelsValue: any) => {
    if (name === "hotels") {
      navigate(
        `/invoice/list/${page}/${limit}/${
          hotelsValue ? hotelsValue : "all"
        }/${outlet}/${weeknumber}/${invoiceName}/${status}`
      );
      dispatch(
        listingHotels({ hotelName: hotelsValue, limit: 10000, page: 0 })
      );
    }
    if (name === "outlets") {
      dispatch(
        listingInvoices({
          limit: limit,
          page: 1,
          outlet: hotelsValue,
          hotel: hotel === "all" ? "" : hotel,
        })
      );
      navigate(
        `/invoice/list/${page}/${limit}/${hotel}/${
          hotelsValue ? hotelsValue : "all"
        }/${weeknumber}/${invoiceName}/${status}`
      );
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotelName: hotelsValue,
          limit: 10000,
          page: 0,
        })
      );
    }
    if (name === "invoice") {
      navigate(
        `/invoice/list/${page}/${limit}/${hotel}/${outlet}/${weeknumber}/${
          hotelsValue ? hotelsValue : "all"
        }/${status}`
      );
      dispatch(
        listingInvoices({
          limit: limit,
          page: 1,
          outlet: outlet === "all" ? "" : outlet,
          hotel: hotel === "all" ? "" : hotel,
          invoice_name: hotelsValue,
        })
      );
    }
    if (name === "userStatus") {
      navigate(
        `/invoice/list/${page}/${limit}/${hotel}/${outlet}/${weeknumber}/${invoiceName}/${
          hotelsValue ? hotelsValue : "all"
        }`
      );
      dispatch(
        listingInvoices({
          limit: limit,
          page: 1,
          outlet: outlet === "all" ? "" : outlet,
          hotel: hotel === "all" ? "" : hotel,
          invoice_name: invoiceName === "all" ? "" : invoiceName,
          status: hotelsValue === "all" ? "" : hotelsValue,
        })
      );
    }
  };

  const filterValueChangeByHotelsOrOutlets = (name: any, value: any) => {
    if (name === "hotels") {
      navigate(
        `/invoice/list/${page}/${limit}/${
          value[0]?.value ? value[0]?.value : "all"
        }/${outlet}/${weeknumber}/${invoiceName}/${status}`
      );
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotel: value[0]?.value ? value[0]?.value : "",
          page: 1,
          limit: 10000,
        })
      );
      dispatch(
        listingInvoices({
          limit: limit,
          page: 1,
          hotel: value[0]?.value,
          outlet: outlet === "all" ? "" : outlet,
        })
      );
    }
    if (name === "outlets") {
      navigate(
        `/invoice/list/${page}/${limit}/${hotel}/${
          value[0]?.value ? value[0]?.value : "all"
        }/${weeknumber}/${invoiceName}/${status}`
      );
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotel: hotel === "all" ? "" : hotel,
          page: 1,
          limit: 10000,
        })
      );
      dispatch(
        listingInvoices({
          limit: limit,
          page: 1,
          outlet: value[0]?.value ? value[0]?.value : "",
          hotel: hotel === "all" ? "" : hotel,
        })
      );
    }
    if (name === "invoice") {
      navigate(
        `/invoice/list/${page}/${limit}/${hotel}/${outlet}/${weeknumber}/${
          value[0]?.label ? value[0]?.label : "all"
        }/${status}`
      );
      dispatch(
        listingInvoices({
          limit: limit,
          page: 1,
          outlet: outlet === "all" ? "" : outlet,
          hotel: hotel === "all" ? "" : hotel,
          invoice_name: value[0]?.label ? value[0]?.label : "",
        })
      );
    }
  };

  const searchWithWeekNumber = (week: any) => {
    navigate(
      `/invoice/list/${page}/${limit}/${hotel}/${outlet}/${
        week.target.value ? week.target.value : "all"
      }/${invoiceName}/${status}`
    );
    dispatch(
      listingInvoices({
        limit: limit,
        page: 1,
        outlet: outlet === "all" ? "" : outlet,
        hotel: hotel === "all" ? "" : hotel,
        invoice_name: invoiceName === "all" ? "" : invoiceName,
        weekNumber: week.target.value ? week.target.value : "",
      })
    );
  };

  const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.hotelName ? list.hotelName : " ",
      })
    );
    return hotel;
  };

  const outletOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.outletName ? list.outletName : " ",
      })
    );
    return hotel;
  };

  const InvoiceNameOptionsListForSearch = (data: any) => {
    let invoice: any = [];
    data?.data?.invoice_list?.map((invoicename: any, index: any) =>
      invoice.push({
        id: index,
        value: invoicename._id,
        label: invoicename.invoiceUID,
      })
    );
    return invoice;
  };

  const saveSelectedInvoices = (e: any, cell: any) => {
    if (e.target.checked) {
      // setCount(count + 1)
      setCount((prevState: any) => ++prevState);
      setSelectedtomerge((prev: any) => [...prev, cell._id]);
    } else {
      const index = selectedInvoicesForMerging?.findIndex(
        (merging: any) => merging === cell._id
      );
      setCount((prevState: any) => --prevState);
      setSelectedtomerge((prevState: any) => [
        ...prevState,
        prevState.splice(index, 1),
      ]);
      // selectedInvoicesForMerging.splice(index,1)
    }
  };

  const mergeInvoices = () => {
    if (selectedtomerge.length > 2) {
      toast.warning(
        "Plese merge only 2 invoices from the same outlet at the same time"
      );
    } else {
      dispatch(mergeInvoiceLoadingAction({}));
      dispatch(
        mergeInvoice({
          invoice_id1: selectedtomerge[0],
          invoice_id2: selectedtomerge[1],
        })
      );
    }
  };

  // edit invoice
  const handleEditInvoice = (id: any) => {
    console.log("the id for editing", id);
    navigate(`/invoice/edit/${id}`);
  };
  const handleViewInvoice = (id: any) => {
    console.log("the view invoice", id);
    navigate(`/invoice/${id}`);
  };
  // invoice column
  const columns = [
    {
      Header: "",
      accessor: "mergeID",
      sort: false,
    },
    {
      Header: "Invoice ID",
      accessor: "invoiceUID",
      sort: false,
    },
    {
      Header: "Amount",
      accessor: "amount",
      sort: false,
    },
    {
      Header: "Invoice Date",
      accessor: "invoiceDate",
      sort: false,
    },
    {
      Header: "Received Date",
      accessor: "receivedDate",
      sort: false,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: false,
    },
    {
      Header: "Invoice Details",
      accessor: "",
      Cell: ({ cell }: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
         { cell.row.original.amount ? <span style={{cursor:"pointer"}} onClick={() => handleEditInvoice(cell.row.original.id)}>
            <i className="bi bi-pencil-square"></i>
          </span> : <></>}{" "}
          &nbsp;
          <span  style={{cursor:"pointer"}}onClick={() => handleViewInvoice(cell.row.original.id)}>
            <i className="bi bi-eye"></i>
          </span>
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: ({ cell }: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
          {(sendInvoiceLoading && (selectedCellId === cell.row.original._id)) ? ( <span
            className="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>):(
<span
          style={{cursor:"pointer"}}
            onClick={() => {
              setSelectedCellID(cell.row.original.id)
              dispatch(sendInvoicesLoading({}))
              dispatch(SendInvoice(cell.row.original.id));
            }}
          >
            <i className="bi bi-send"></i>
          </span>
          )}
          
          &nbsp;
          {(downloadInvoiceLoading && (selectedCellId === cell.row.original._id) )? (
            <span
            className="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>
          ):( <span
            style={{cursor:"pointer"}}
              onClick={() => {
                setSelectedCellID(cell.row.original.id)
                dispatch(downloadInvoicesLoading({}))
                dispatch(DownloadInvoice(cell.row.original.id));
              }}
            >
              <i className="bi bi-download"></i>
            </span>)}
         
        </div>
      ),
    },
  ];

  //  set default value for filters
  const defaultValue = (options: any, id: any) => {
    const filters = options?.filter((op: any) => op.value === id);
    return filters[0]?.label;
  };

  // date tabs
  const tabContents = () => {
    let i;
    let j;
    const arr = [];
    for (i = 7; i >= 0; i--) {
      arr.push({
        index: i,
        date: moment(new Date()).subtract(i, "days"),
        dateShow: moment(new Date()).subtract(i, "days").format("MMM DD"),
      });
    }
    for (j = 1; j <= 7; j++) {
      arr.push({
        index: j,
        date: moment(new Date()).add(j, "days"),
        dateShow: moment(new Date()).add(j, "days").format("MMM DD"),
      });
    }
    return arr;
  };

  // modal controls

  const openModal = () => setCreateInvoiceModal(!createInvoiceModal);

  const toggleResponsiveModalDate = () =>
    setResponsiveModalDate(!responsiveModalDate);
  const toggleResponsiveModalForCreateInvoice = () =>
    setCreateInvoiceModal(!createInvoiceModal);

  // create invoice form submit
  const submitNewInvoice = (
    formvalues: any,
    hotel: any,
    formValuesArray: any
  ) => {
    const payload = {
      ...formvalues,
      hotel: hotel,
      invoiceItems: formValuesArray.invoiceItems,
      outlet: formvalues.outlet[0].value,
      invoiceType: formvalues.invoiceType[0].value,
    };
    dispatch(createNewInvoiceLoading({}));
    dispatch(createNewInvoice(payload));
    toggleResponsiveModalForCreateInvoice();
  };

  return (
    <>
      <Modal show={responsiveModalDate} onHide={toggleResponsiveModalDate}>
        <DateRangePicker
          onChange={(item: any) => setState([item.selection])}
          // onChange={item => setState([item.selection])}
          // showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </Modal>
      {/* create invoice */}
      <CreateInvoiceModal
        createInvoiceModal={createInvoiceModal}
        toggleResponsiveModalForCreateInvoice={
          toggleResponsiveModalForCreateInvoice
        }
        submitNewInvoice={submitNewInvoice}
        filterValueChangeByHotelsOrOutlets={filterValueChangeByHotelsOrOutlets}
        hotelsList={
          hotelOptionsListForSearch(hotelsList)
            ? hotelOptionsListForSearch(hotelsList)
            : []
        }
        outletsList={
          outletOptionsListForSearch(outletsList)
            ? outletOptionsListForSearch(outletsList)
            : []
        }
        searchByName={searchByHotelNameForModal}
      />
      <h1>Invoice</h1>
      <div>
        <CommonSearchBar
          name={weeknumber}
          searchTitle={"Week Number"}
          search={(e: any) => searchWithWeekNumber(e)}
          searchByName={searchByHotelName}
          filterValueChanged={filterValueChangeByHotelsOrOutlets}
          filters={[
            {
              label: "Hotels",
              name: "hotels",
              placeholder: "Please select a hotel",
              options: hotelOptionsListForSearch(hotelsList),
              value: defaultValue(hotelOptionsListForSearch(hotelsList), hotel),
              isSelect: true,
            },

            {
              label: "Outlets",
              name: "outlets",
              placeholder: "Please select a outlet",
              options: outletOptionsListForSearch(outletsList),
              value: defaultValue(
                outletOptionsListForSearch(outletsList),
                outlet
              ),
              isSelect: true,
            },
          ]}
        />

        <CommonSearchBar
          defaultoptionValue={status}
          searchByName={searchByHotelName}
          filterValueChanged={filterValueChangeByHotelsOrOutlets}
          filters={[
            {
              label: "Invoice",
              name: "invoice",
              placeholder: "Please select a invoice",
              options: InvoiceNameOptionsListForSearch(invoicelist),
              value: defaultValue(
                InvoiceNameOptionsListForSearch(invoicelist),
                invoiceName
              ),
              isSelect: true,
            },
            {
              label: "Status",
              name: "userStatus",
              // placeholder: "Please select  Status",
              isSelect: false,

              options: [
                { id: 0, value: "all", label: "Please select a  Status" },
                { id: 1, value: "PENDING", label: "PENDING" },
                { id: 2, value: "SUBMITTED", label: "SUBMITTED" },
                { id: 3, value: "RECEIVED BACK", label: "RECEIVED BACK" },
                {
                  id: 4,
                  value: "RECEIVED BY THIRD PARTY",
                  label: "RECEIVED BY THIRD PARTY",
                },
              ],
            },
          ]}
        />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "10px",
          }}
        >
          {state && (
            <span
              style={{
                background: "lightgrey",
                width: "20%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "10px",
                padding: "6px",
              }}
              onClick={() => setResponsiveModalDate(true)}
            >
              {moment(state[0]?.startDate).format("MMM DD")} -{" "}
              {moment(state[0]?.endDate).format("MMM DD")}
            </span>
          )}
        </div>
        <div>
          <Col xl={12}>
            <Card>
              <Card.Body>
                <Tab.Container defaultActiveKey="Profile">
                  <Nav
                    as="ul"
                    variant="pills"
                    justify
                    className="navtab-bg"
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      flexWrap: "nowrap",
                    }}
                    defaultActiveKey={moment(startDate).format("MMM DD")}
                  >
                    {(tabContents() || []).map((tab, index) => {
                      return (
                        <Nav.Item as="li" key={index}>
                          <Nav.Link
                            className="cursor-pointer"
                            href="#"
                            style={{
                              cursor: "pointer",
                            }}
                            eventKey={tab.dateShow}
                            // eventKey={(moment(startDate).format("MMM DD") === moment(endDate).format("MMM DD")) ? moment(startDate).format("MMM DD") : ''}
                            onClick={() => {
                              console.log(
                                "diff",
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "YYYY-MM-DD", "days")
                                )
                              );
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) < 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    endDate: moment(tab.date).toDate(),
                                    startDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) > 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    startDate: moment(tab.date).toDate(),
                                    endDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                            }}
                          >
                            {tab.dateShow}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
      {invoicelist?.total_records}
      <div style={{display:'flex', justifyContent:'space-evenly'}}>
        <Row>
          <Col md={3}>
          <CountDashboard
            title={'TOTAL'}
            imageUrl={invoiceLogo}
            count={invoicelist?.data?.total_records}
            color="#afe398"
            textColor="white"
            />
          </Col>
          <Col md={3}>
          <CountDashboard
            title={'PENDING'}
            imageUrl={invoiceLogo}
            count={invoicelist?.data?.total_pending}
            color="#e5b524"
            textColor="white"
            />
          </Col>
          <Col md={3}>
          <CountDashboard
            title={'SUBMITTED'}
            imageUrl={invoiceLogo}
            count={invoicelist?.data?.total_submitted}
            color="#6fa8dc"
            textColor="white"
            />
          </Col>
          <Col md={3}>
          <CountDashboard
            title={'RECEIVED'}
            imageUrl={invoiceLogo}
            count={invoicelist?.data?.total_received}
            color="#d9d2e9"
            textColor="white"
            />
          </Col>
        </Row>  
        </div>
      <Card style={{ marginTop: "2%" }}>
        <Card.Body>
          <Row>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                style={{ float: "right" }}
                variant="success"
                className="waves-effect waves-light"
                onClick={openModal}
              >
                {createInvoiceLaoding ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                    <span>Add Invoice</span>
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-plus-circle me-1"></i> Add Invoice
                  </>
                )}
              </Button>
              &nbsp;&nbsp;
             
             {count === 2 && (
              <>
              <Button
                style={{ float: "right" }}
                variant="success"
                className="waves-effect waves-light"
                onClick={mergeInvoices}
              >
                {mergeInvoiceLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                    <span>Merge Invoice</span>
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-plus-circle me-1"></i>Merge Invoice
                  </>
                )}
              </Button>
              </>
             )} 
            </div>
          </Row>
         
          <Row>
            <Table
              columns={columns}
              data={InvoiceListTableColumns(
                invoicelist,
                saveSelectedInvoices,
                count,
                selectedtomerge
              )}
              isSortable={true}
              pagination={false}
            />
          </Row>
          {invoicelistLoading && <Loader />}
        </Card.Body>
      </Card>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={invoicelist?.data?.total_records}
        pageSize={10}
        onPageChange={(page: any) => {
          setCurrentPage(page);
          navigate(`/invoice/list/${page}/${limit}/all/all/all/all/all`);
          dispatch(
            listingInvoices({
              page: page,
              limit: limit,
              //   startDate:moment().format("YYYY-MM-DDThh:mm:ss"),
              //   endDate:moment().format("YYYY-MM-DDThh:mm:ss"),
            })
          );
        }}
      />
    </>
  );
};

export default InvoiceListingPage;
