export enum OutletsActionTypes {
  API_OUTLETS_RESPONSE_SUCCESS = "@@outlets/API_OUTLETS_RESPONSE_SUCCESS",
  API_OUTLETS_RESPONSE_ERROR = "@@outlets/API_OUTLETS_RESPONSE_ERROR",

  LIST_OUTLETS = "@@outlets/LIST_OUTLETS",
  LIST_OUTLETS_LOADING = "@@outlet/LIST_OUTLETS_LOADING",
  CREATE_OUTLETS = "@@outlets/CREATE_OUTLETS",
  EDIT_OUTLETS = "@@outlets/EDIT_OUTLETS",
  DELET_OUTLETS = "@@outlets/DELETE_OUTLETS",
  EDIT_OUTLETS_SELECTED = "@@outlets/EDIT_OUTLETS_SELECTED",
  SEARCH_OUTLETS_BY_HOTELS = "@@outlets/SEARCH_OUTLEST_BY_HOTELS",
  // ADD_OUTLET_USERS_LOADING = "@@outlets/ADD_OUTLET_USERS_LOADING",
  ADD_OUTLET_USERS = "@@outlets/ADD_OUTLET_USERS",

  GET_COLUMNS = "@@outlets/GET_COLUMNS",
  CREATE_COLUMNS = "@@outlets/CREATE_COLUMNS",

  RESET_OUTLETS = "@@outlets/RESET_OUTLETS",
}
