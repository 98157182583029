import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import io from "socket.io-client";
import config from "../../config";
// components


import ChatUsers from "./ChatUsers";
import ChatArea from "./ChatArea";
// dummy data
import { useDispatch, useSelector } from "react-redux";
import { getChats, getChatsLoading, getMessages, getMessagesLoading } from "../../redux/chat/action";
import { listingUsers } from "../../redux/users/actions";
import { RootState } from "../../redux/store";
import Loader from "../../components/Loader";
const ChatApp = () => {
  const socket = io(config.API_SOCKET_URL);
  // const socket = io("http://localhost:5001");
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [chat, setChat] = useState<any>([]);
  const [userMessages, setUserMessages] = useState<any[]>([]);
  const [newGroup, setNewGroup] = useState("");
  const { chats,chatsLoading,messageLoading } = useSelector(
    (state: RootState) => state.ChatReducer
  );
  const { usersList } = useSelector((state: RootState) => state.UsersReducer);
  const ID = JSON.parse(localStorage.getItem('userLoggedIn')|| '{}')
  const [room, setRoom] = useState(""); // Room name
localStorage.setItem("newMessage","0");
  // console.log("the chat", chats?.result?.[0]);
  const sendNewMessages = () => {
    dispatch(getChats());
  };
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("userLoggedIn") || "{}");
    dispatch(getChatsLoading())
    // getchat list and users for chat user component
    dispatch(getChats());
    dispatch(listingUsers({ userStatus: "VERIFIED", page: 1, limit: 1000 }));
    socket.emit("join room", room);
    //join to socket
    socket.emit("join", { userId: userId?._id, role: userId?.roles });
    // Listen for messages from the server
    socket.on("chat message", (msg) => {
      setChat((prevChat: any) => [...prevChat, msg]);
    });
    // Cleanup on component unmount
    return () => {
      socket.off("chat message");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //setSelectedUser(chats?.result?.[0]);
    
   
    if(newGroup){
      console.log("newgroup====>",newGroup)
      const newlyCreatedGroup = chats?.result.filter((item:any)=> item.roomName  === newGroup);
      setSelectedUser(newlyCreatedGroup[0]);
      setNewGroup("");
      setRoom(newlyCreatedGroup[0]?.roomName);
      if(newlyCreatedGroup[0]?.roomName){
        socket.emit("initiate_chat", { userId: newlyCreatedGroup[0]?.roomName });
        }
    }
    // else {
    //   //setSelectedUser(chats?.result?.[0]);
    //   if(chats?.result?.[0]?.roomName){
    //     socket.emit("initiate_chat", { userId: chats?.result?.[0]?.roomName });
    //     }
    // }
    // setGroups(chats?.result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats?.result]);

  useEffect(() => {
    if (selectedUser?.roomName) { 
     
      dispatch(getMessages(selectedUser?.roomName));
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser?.roomName]);

  socket.on("chat_started", ({ roomId }: any) => {
     console.log("here room id", roomId);
     if(!room){
      setRoom(roomId);
     }
   // setRoom(roomId);
    setNewGroup(roomId)
  });
  const onUserChange = (user: any) => {
    setSelectedUser(user);
    dispatch(getMessagesLoading())
    // Change the room dynamically if needed
    dispatch(getChats());
    setRoom(`${user.id}`); // For example, room name based on user ID
  };
  socket.on("receive_message", (data: any) => {
   console.log("message recieved====>",data.message)
    if (ID._id !== data.message.senderId)
      setUserMessages((prevMessages) => [...prevMessages, data.message]);
    dispatch(getChats())
  });

  return (
    <>
      <h1>Chat</h1>

        <Row>
        <Col lg={4} xl={3}>
          { chatsLoading ? <Loader/> : chats?.result && (
            <ChatUsers
              chats={chats?.result}
              allUsers={usersList?.data}
              onUserSelect={onUserChange}
              // socket={false}
              socket={socket}
              selectedUser={selectedUser}
            />
          )}
        </Col>
        <Col lg={8} xl={9}>
          {  room &&
            <ChatArea
              sendMessage={sendNewMessages}
              selectedUser={selectedUser}
              socket={socket}
              // socket={false}
              room={room}
              setUserMessages={setUserMessages}
              userMessages={userMessages}
            />
          }
        </Col>
      </Row>
    </>
  );
};

export default ChatApp;
