import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { Saga, SagaIterator } from "@redux-saga/core";


// helpers
import {
  listSlots as listSlotsApi,
  addSlots as addSlotsApi,
  editSlots as editSlotsApi,
  getSlotsById as getSlotsByIdApi,
  cancelSlots as cancelSlotsApi,
  listPositions as listPositionsApi,
  addPositions as addPositionsApi,
  listUsersSlots as listUsersSlotsApi,
  Cancelslost as CancelslostApi
} from "../../helpers/api/slots";

import{ listusersSlotsById as listusersSlotsByIdApi} from "../../helpers/api/users";
// constants
import { SlotsActionTypes } from "./constants";
// actions
import { apiOutletsResponseError, apiOutletsResponseSucces } from "./actions";
import { toast } from "react-toastify";



function* listSlots({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(listSlotsApi, searchParams);
    console.log('the users in te slosts', response)
    yield put(apiOutletsResponseSucces(SlotsActionTypes.LIST_SLOTS_LOADING, {}))
    yield put(apiOutletsResponseSucces(SlotsActionTypes.LIST_SLOTS, response.data.result))
  }
  catch(err: any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.LIST_SLOTS, err))
  }
}
function* listSlotsloading():SagaIterator{
  try{
    yield put(apiOutletsResponseSucces(SlotsActionTypes.LIST_SLOTS_LOADING, {}))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(SlotsActionTypes.LIST_SLOTS_LOADING, err))
  }
}
function* createSlotsloading():SagaIterator{
  try{
    yield put(apiOutletsResponseSucces(SlotsActionTypes.CREATE_SLOTS_LOADING, {}))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(SlotsActionTypes.CREATE_SLOTS_LOADING, err))
  }
}

function* createSlots({payload: slotsDetails,
  type}: any): SagaIterator{
  try{
    
    const response = yield call(addSlotsApi, slotsDetails);
    toast.success(response.data.info.msg);
    // yield put(apiOutletsResponseSucces(SlotsActionTypes.CREATE_SLOTS_LOADING, {}))
    // console.log("the data", response)
    yield put(apiOutletsResponseSucces(SlotsActionTypes.CREATE_SLOTS, {}))
    
  }
  catch(err: any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.CREATE_SLOTS, err))
  }
}

function* cancelSlots({payload: id,
  type}: any): SagaIterator{
  try{
    console.log("thhe payload", id)
    // yield put(apiOutletsResponseSucces(SlotsActionTypes.DELET_SLOTS_LOADING, {}))
    const response = yield call(CancelslostApi, id);
    toast.success(response.data.info.msg);
    yield put(apiOutletsResponseSucces(SlotsActionTypes.DELET_SLOTS, {}))
    
  }
  catch(err: any){
    
    toast.error(err.response.data.error.msg);
    yield put(apiOutletsResponseError(SlotsActionTypes.DELET_SLOTS, err))
  }
}
function* getSlotsById({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(getSlotsByIdApi, searchParams);
    console.log('the details',response)
    yield put(apiOutletsResponseSucces(SlotsActionTypes.EDIT_SLOTS_SELECTED, response.data.result[0]))
  }
  catch(err: any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.EDIT_SLOTS_SELECTED, err))
  }
}

function* getSlotsByUserId({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(listusersSlotsByIdApi, searchParams);
    console.log('the details',response)
    yield put(apiOutletsResponseSucces(SlotsActionTypes.GET_SLOTS_BY_USER_ID, response.data.result[0]))
  }
  catch(err: any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.GET_SLOTS_BY_USER_ID, err))
  }
}

function* editSlots({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    console.log("the slot updationg", details)
    const repsonse = yield call(editSlotsApi, details);
    yield put(apiOutletsResponseSucces(SlotsActionTypes.EDIT_SLOTS, repsonse.data.result[0]))
    toast.success(repsonse.data.info.msg);
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.EDIT_SLOTS, err));
 
  }
}
function* deleteSlots({
  id,
  type,
}: any): SagaIterator{
  try{
    const repsonse = yield call(cancelSlotsApi, id);
    // if(repsonse) yield call(listSlots,{page:0,limit:5})
    yield put(apiOutletsResponseSucces(SlotsActionTypes.DELET_SLOTS,{}))
    toast.success(repsonse.data.info.msg);
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.DELET_SLOTS, err));
 
  }
}


function* addPositions({
  details,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', details)
    const repsonse = yield call(addPositionsApi, details);
    console.log('theresp, response', repsonse)
    yield put(apiOutletsResponseSucces(SlotsActionTypes.LIST_POSITIONS_LOADING,{}))
    yield put(apiOutletsResponseSucces(SlotsActionTypes.ADD_POSITION,{}))
    toast.success(repsonse.data.info.msg);
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.ADD_POSITION, err));
 
  }
}
function* listPositions({
  details,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', details)
    const repsonse = yield call(listPositionsApi,details);
    console.log('theresp, response', repsonse)
    yield put(apiOutletsResponseSucces(SlotsActionTypes.LIST_POSITIONS_LOADING,{}))
    yield put(apiOutletsResponseSucces(SlotsActionTypes.LIST_POSITIONS,repsonse.data.result))
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.LIST_POSITIONS, err));
 
  }
}

function* listUserSlots({
  details,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', details)
    const repsonse = yield call(listUsersSlotsApi, details);
    console.log('theresp, response', repsonse)
    yield put(apiOutletsResponseSucces(SlotsActionTypes.LIST_SLOT_USERS_LOADING,{}))
    yield put(apiOutletsResponseSucces(SlotsActionTypes.LIST_SLOT_USERS,repsonse.data.result.data))
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
   
    yield put(apiOutletsResponseError(SlotsActionTypes.LIST_SLOT_USERS, err));
 
  }
}

// function* resetValues({
//   type,
//   payload,
// }: any): SagaIterator{
//   try{
//     yield put(apiOutletsResponseSucces(SlotsActionTypes.RESET_SLOTS,{}))
//   }
//   catch(err:any){
//     yield put(apiOutletsResponseError(SlotsActionTypes.RESET_SLOTS, err));
 
//   }
// }


export function* watchListingSlots() {
  yield takeEvery(SlotsActionTypes.LIST_SLOTS, listSlots);
}

export function* watchListingSlotsLoading() {
  yield takeEvery(SlotsActionTypes.LIST_SLOTS_LOADING, listSlotsloading);
}
// export function* watchCancelSlots(){
//   yield takeEvery(SlotsActionTypes.DELET_SLOTS, cancelSlots)
// }
export function* watchCreatingSlotsLoading() {
  yield takeEvery(SlotsActionTypes.CREATE_SLOTS_LOADING, createSlotsloading);
}
export function* watchCreatingSlots() {
  yield takeEvery(SlotsActionTypes.CREATE_SLOTS, createSlots);
}
export function* watchgetSlotsById() {
  yield takeEvery(SlotsActionTypes.EDIT_SLOTS_SELECTED, getSlotsById);
}
export function* watchgetSlotsByUserId() {
  yield takeEvery(SlotsActionTypes.GET_SLOTS_BY_USER_ID, getSlotsByUserId);
}
export function* watchEditSlot() {
  yield takeEvery(SlotsActionTypes.EDIT_SLOTS, editSlots);
}
export function* watchDeletSlot() {
  yield takeEvery(SlotsActionTypes.DELET_SLOTS, deleteSlots);
}
export function* watchAddPositions() {
  yield takeEvery(SlotsActionTypes.ADD_POSITION, addPositions);
}
export function* watchListPositions() {
  yield takeEvery(SlotsActionTypes.LIST_POSITIONS, listPositions);
}

export function* watchListUserSlots() {
  yield takeEvery(SlotsActionTypes.LIST_SLOT_USERS, listUserSlots);
}

function* slotsSaga() {
  yield all([
    fork(watchListingSlots),
    fork(watchCreatingSlots),
    fork(watchgetSlotsById),
    fork(watchEditSlot),
    fork(watchDeletSlot),
    fork(watchAddPositions),
    fork(watchListPositions),
    fork(watchListUserSlots),
    fork(watchCreatingSlotsLoading),
    fork(watchgetSlotsByUserId),
    fork(watchListingSlotsLoading)
    // fork(watchCancelSlots)
  ]);
}

export default slotsSaga;
