import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  deletehotels as deleteHotelApi,
  listhotels as listgroupsApi,
  listhotelsById as listHotelsByIdApi,
  updateHotelsById  as updateHotelsByIdApi,
  addHotels as addHotelsApi
} from "../../helpers/api/hotels";


// constants
import { HotelActionTypes } from "./constants";
// actions
import { apiHotelsResponseError, apiHotelsResponseSuccess } from "./actions";
import { toast } from "react-toastify";


function* listHotels({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(listgroupsApi, searchParams);
 
    yield put(apiHotelsResponseSuccess(HotelActionTypes.LIST_HOTELS, response.data.result))
  }
  catch(err: any){
    yield put(apiHotelsResponseError(HotelActionTypes.LIST_HOTELS, err))
  }
}

function* listHotelsById({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    const repsonse = yield call(listHotelsByIdApi, details);
    console.log('theresp, response', repsonse)
    yield put(apiHotelsResponseSuccess(HotelActionTypes.EDIT_HOTELSS_SELECTED, repsonse.data.result[0]))
  }
  catch(err:any){
    yield put(apiHotelsResponseError(HotelActionTypes.EDIT_HOTELSS_SELECTED, err));
 
  }
}
function* deleteHotel({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log("the payel", id)
    const repsonse = yield call(deleteHotelApi, id);
    toast.success(repsonse.data.info.msg);
    if(repsonse) yield call(listHotels,{page:0,limit:5})
      window.location.reload()
    yield put(apiHotelsResponseSuccess(HotelActionTypes.DELET_HOTELS,{}))
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
 
    yield put(apiHotelsResponseError(HotelActionTypes.DELET_HOTELS, err));
 
  }
}
function* createHotels({
  payload: details,
  type
}: any): SagaIterator{
  try{
    const repsonse = yield call(addHotelsApi, details);
    console.log('theresp, response', repsonse)
    if(repsonse) {
      window.location.reload()
      yield call(listHotels,{page:0,limit:5})
    }
    toast.success(repsonse.data.info.msg);
    yield put(apiHotelsResponseSuccess(HotelActionTypes.CREATE_HOTELS,{}))
  }
  catch(err:any){
    console.log('theresp, response', err)
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
 
    yield put(apiHotelsResponseError(HotelActionTypes.CREATE_HOTELS, err));
 
  }
}
function* updateHotels({
  payload: hotelDetails,
  type,
}: any):SagaIterator{
  try{
    const response = yield call(updateHotelsByIdApi , hotelDetails)
    if(response) {
      console.log('the resppnnse inns reduc of hotels', response)
      // yield call(listgroupsApi,{page:0,limit:5})
      toast.success(response.data.info.msg);
      window.location.reload()
    }
    yield put(apiHotelsResponseSuccess(HotelActionTypes.EDIT_HOTELS,{}))
  }
  catch(err:any){
    if(err.response.status === 500 || err.response.status === 403 || err.response.status === 400 ){
      toast.error("Something went wrong. Please reload and try again after some time")
    }
    toast.error(err.response.data.error.msg? err.response.data.error.msg : err.response.data.error);
 
    yield put(apiHotelsResponseError(HotelActionTypes.EDIT_HOTELS, err));
 
  }
}
function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    
    yield put(apiHotelsResponseSuccess(HotelActionTypes.RESET_HOTELS,{}))
  }
  catch(err:any){
    yield put(apiHotelsResponseError(HotelActionTypes.RESET_HOTELS, err));
 
  }
}


export function* watchListingHotels() {
  yield takeEvery(HotelActionTypes.LIST_HOTELS, listHotels);
}
export function* watchListSupportById(): any {
  yield takeEvery(HotelActionTypes.EDIT_HOTELSS_SELECTED, listHotelsById);
}
export function* watchDeleteHotel(): any {
  yield takeEvery(HotelActionTypes.DELET_HOTELS, deleteHotel);
}

export function* watchResetSelectedHotel(): any {
  yield takeEvery(HotelActionTypes.RESET_HOTELS, resetValues);
}
export function* watchUpateSelectedHotels():any{
  yield takeEvery(HotelActionTypes.EDIT_HOTELS, updateHotels)
}
export function* watchCreateHotels():any{
  yield takeEvery(HotelActionTypes.CREATE_HOTELS, createHotels)
}
// export function* watchLogout() {
//   yield takeEvery(HotelActionTypes.LOGOUT_USER, logout);
// }

// export function* watchSignup(): any {
//   yield takeEvery(HotelActionTypes.SIGNUP_USER, signup);
// }

// export function* watchForgotPassword(): any {
//   yield takeEvery(HotelActionTypes.FORGOT_PASSWORD, forgotPassword);
// }

function* hotelSaga() {
  yield all([
    fork(watchListingHotels),
    fork(watchListSupportById),
    fork(watchDeleteHotel),
    fork(watchResetSelectedHotel),
    fork(watchUpateSelectedHotels),
    fork(watchCreateHotels)
    // fork(watchLogout),
    // fork(watchSignup),
    // fork(watchForgotPassword),
  ]);
}

export default hotelSaga;
