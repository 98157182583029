
// constants
import { PushNotificationActionTypes } from "./constants";

export interface PushNotificationActionType {
  type:
  | PushNotificationActionTypes.API_NOTIFICATIONS_RESPONSE_ERROR
  | PushNotificationActionTypes.API_NOTIFICATIONS_RESPONSE_SUCCESS
  | PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS
 | PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS_LOADING
 | PushNotificationActionTypes.RESET_NOTIFICATIONS
  payload: {} | string;
}

// common success
export const apiPushNotificationResponseSuccess = (
  actionType: string,
  data: any | {}
): PushNotificationActionType => ({
  type: PushNotificationActionTypes.API_NOTIFICATIONS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiPushNotificationResponseError = (
  actionType: string,
  error: string
): PushNotificationActionType => ({
  type: PushNotificationActionTypes.API_NOTIFICATIONS_RESPONSE_ERROR,
  payload: { actionType, error },
});



export const createNewPushNotification = (
  payload: any,
): any => ({
  type:PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS,
  payload: payload,
})

export const createNewPushNotificationLoading = (
   
  ): any => ({
    type:PushNotificationActionTypes.CREATE_NEW_NOTIFICATIONS_LOADING,
    payload: {},
  })


